import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getAuth, removeAuth } from '@services/identity.service';
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { logoutUser } from '@services/users.service';
import useScrollToTopOnRouteChange from '@ui/hooks/useScrollToTopOnRouteChange';
import useBroadcastChannel from 'hooks/useBroadcastChannel';
import {
    getFestivalConfiguration,
    listenBroadCastMessage,
    reloadAuthenticatedTabs,
} from '@helpers/miscelleanous';
import { hideFreshchat } from '@components/FreshChat';

const EmptyLayout = ({ children, ...pageProps }) => {
    const router = useRouter();
    const [festiveSeason, setFestiveSeason] = useState(null);
    useBroadcastChannel('logout-event', (log) => {
        // Clear user-related data, redirect to the login page, etc.
        listenBroadCastMessage(log, 'logout', router);
    });
    useScrollToTopOnRouteChange();
    // code for auto-logging out after 2 hrs of inactivity
    const debounceRef = useRef();
    const time = 1000 * 60 * 60 * 2; // 2 hrs

    const logout = async () => {
        await logoutUser(getAuth());
        removeAuth();
        router.reload(window.location.pathname);
        reloadAuthenticatedTabs();
    };

    useEffect(() => {
        debounceRef.current();
        tracker();
        (async () => {
            const festiveSeason = await getFestivalConfiguration();
            setFestiveSeason(festiveSeason);
        })();
        if(router?.pathname === '/adminLogin')
           hideFreshchat();
    }, []);

    debounceRef.current = (function debounce() {
        let timerId;

        return function () {
            if (timerId) {
                clearTimeout(timerId);
            }

            timerId = setTimeout(() => {
                if (getAuth()) {
                    logout();
                }
            }, time);
        };
    })();

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...pageProps, festiveSeason});
        }
        return child;
    });

    function tracker() {
        window.addEventListener('mousemove', debounceRef.current);
        window.addEventListener('scroll', debounceRef.current);
        window.addEventListener('keydown', debounceRef.current);
    }

    return (
        <div className={`page-content`}>
            {childrenWithProps}
        </div>
    );
};

EmptyLayout.propTypes = {
    children: PropTypes.node,
};

export default EmptyLayout;
